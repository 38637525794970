import createDataContext from "./createContext";
import { BaseService } from "../api";
import { BaseRedirect } from "../api";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";
import { Route } from "react-router-dom";
import { WebTrakingService } from "../api";
import { sendDialogCustuom } from "../hooks/useDialogCustuom";


const END_LOGIN = "end_login";
const LOGOUT = "logout";
const IS_VALID_ADD = "is_valid_add";
const PRIVACY_TEXT = "privacy_text"

const INITIAL_STATE = {
  user: null,
  isValidAdd:null,
  configAll:{},
  titoloPrivacyDialog : "",
  corpoPrivacyDialog : ""
};


const authReducer = (state, action) => {
  switch (action.type) {
   
    case END_LOGIN: {
      const { user, configAll } = action.payload;
      return { ...state, user, configAll};
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
    case IS_VALID_ADD: {
      return { ...state, isValidAdd: action.payload };
    }
    case PRIVACY_TEXT: {
      const {corpo, titolo} = action.payload
      return { ...state, titoloPrivacyDialog: titolo, corpoPrivacyDialog:corpo };
    }
  
    default: {
      return state;
    }
  }
};

const loginforredirect = (dispatch) => async (loginForm, navigate, server1, server2,configUser, getNationsLists) => {
  const response1 = await BaseRedirect.post(`${server1}/login`, {
    jwtusername: loginForm.username,
    jwtpassword: loginForm.password
  });

  const response2 = await BaseRedirect.post(`${server2}/login`, {
    jwtusername: loginForm.username,
    jwtpassword: loginForm.password
  });



  if (response1.data){
    let splitPrm = server1.split("/");
    let url = `${splitPrm[0]}//${splitPrm[2]}/redirectedlogin/${btoa(loginForm.username)}/${btoa(loginForm.password)}`;
    //let url = `${splitPrm[0]}//localhost:3000/redirectedlogin/${btoa(loginForm.username)}/${btoa(loginForm.password)}`;

    window.location.href = url;

  }
  else if (response2.data) {
    let splitPrm = server2.split("/");
    let url = `${splitPrm[0]}//${splitPrm[2]}/redirectedlogin/${btoa(loginForm.username)}/${btoa(loginForm.password)}`;

    window.location.href = url;
  }
  else{
      sendNotification(
         <NotificationContent service={"Errore!"} />,
         "Credenziali non valide",
         "error",
        10
      );
      //TODO: Da collaudare seguente navigate
      navigate("/");

  }


};

const login = (dispatch) => async (loginForm, navigate, server1, configUser, setPrivacyDialogOpen) => {  
    let uri = configUser.server;
    let apriPrivacyDialog = false;
    let ComunicazioneDiServizioActive = false;

    if ( uri  === undefined){
      uri = 'incontra/api/v2';
    }
    const response = await BaseService.post(`${uri}/login`, {
      jwtusername: loginForm.username,
      jwtpassword: loginForm.password
    });

    localStorage.setItem("user",loginForm.username)
    if(response.data){
      const user = response.data;
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('UserDescrizione', response.data.UserDescrizione)
      if(response.data.LuogoRitiro !== undefined){
        localStorage.setItem('luogoRitiro', JSON.stringify(response.data.LuogoRitiro))
      }else{
        localStorage.setItem('luogoRitiro', JSON.stringify([]))
      }


      if (response.data.AccettataPrivacy !== undefined && response.data.AccettataPrivacy === false){
        apriPrivacyDialog = true;
      }

      if (response?.data?.ComunicazioneDiServizio?.Active === true
          && response?.data?.ComunicazioneDiServizio?.Titolo !== undefined           
          && response?.data?.ComunicazioneDiServizio?.Titolo !== '' 
          && response?.data?.ComunicazioneDiServizio?.Testo !== undefined           
          && response?.data?.ComunicazioneDiServizio?.Testo !== ''
      ){
        ComunicazioneDiServizioActive = true;
      };

      if(response.data.configurazione !== undefined){
        localStorage.setItem('configurazione', JSON.stringify(response.data.configurazione))
        if (apriPrivacyDialog){
          setPrivacyDialogOpen(true)
        }
        else{
          let findRoute = response.data.configurazione.find((res, i)=> res.visible)
          navigate("/"+findRoute.name);
        }
      }else{
        localStorage.setItem('configurazione', JSON.stringify([]))
        navigate("/")
      }
      dispatch({ type: END_LOGIN, payload: { user, configAll: configUser} });

      if (response.data.TitoloPrivacyDialog && response.data.CorpoPrivacyDialog){
        dispatch({ type: PRIVACY_TEXT, payload: { corpo:response.data.CorpoPrivacyDialog, titolo: response.data.TitoloPrivacyDialog} });  
      }    

      if (ComunicazioneDiServizioActive){
        sendDialogCustuom(
          <NotificationContent service={response?.data?.ComunicazioneDiServizio?.Titolo} />,
          response?.data?.ComunicazioneDiServizio?.Testo,
          "error",
          200,
          null,
          "",
          "CHIUDI"
        );
      }
    } 
};

const logout = (dispatch) => async (navigate) => {
  navigate("/")
  localStorage.removeItem('token')
  localStorage.removeItem('UserDescrizione')
  localStorage.removeItem('configurazione')
  dispatch({ type: LOGOUT });
};

const addValidCheck = (dispatch) => async (param) => {
  dispatch({
    type: IS_VALID_ADD,
    payload: param,
  });
};



const postAccettaPrivacy = (dispatch) => async (navigate, setPrivacyDialogOpen) => {
  const response = await WebTrakingService.post(`${JSON.parse(localStorage.getItem("configurazioni")).server}/authentication/accettaprivacy`);

  if (response.data.Success){


    sendNotification(
      <NotificationContent service={"OK!"} />,
      response.data.msg,
      "success",
      5
    );

    let configurazione = JSON.parse(localStorage.getItem('configurazione'))
    let findRoute = configurazione.find((res, i)=> res.visible)
    navigate("/"+findRoute.name);
    setPrivacyDialogOpen(false);
  }
  else{
    sendNotification(
      <NotificationContent service={"Attenzione!"} />,
      response.data.Err_Message,
      "warning",
      5
    );
    navigate("/")
    localStorage.removeItem('token')
    localStorage.removeItem('UserDescrizione')
    localStorage.removeItem('configurazione')
    dispatch({ type: LOGOUT });
  }

};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    logout,
    addValidCheck,
    loginforredirect,
    postAccettaPrivacy
  }, // actions
  INITIAL_STATE // initial state
);
