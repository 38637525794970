import React from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  List,
  Slide,
} from "@mui/material";
import { TransitionGroup } from "react-transition-group";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogsCustom = ({ dialog, closeDialog, navigate }) => {




  return (
    <Fade in={true}>
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          zIndex: 9999,
        }}
      >
        <List>
          <TransitionGroup>
            {dialog.map((notif, index) => {
              //29/10/2024 Greg: Inietto questa funzione per il dialog
              //                 comunicazioni di servizio, che non deve
              //                 chiamare navigate.
              const onDialogCustmoButtonClick = () => {   
                if (typeof notif.naviga === 'function' && notif.naviga !== null) {
                  notif.naviga(notif.route);
                }
                closeDialog(notif.id);
              }
            
              return (                
                <Collapse key={"transitioncollapse" + index}>
                  <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                      closeDialog(notif.id);
                    }}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>{notif.title}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {notif.message}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="info"  onClick={()=> onDialogCustmoButtonClick()}>{notif.buttonCaption}</Button>
                    </DialogActions>
                  </Dialog>
                </Collapse>
              );
            })}
          </TransitionGroup>
        </List>
      </Box>
    </Fade>
  );
};

export default DialogsCustom;
