import { 
  Box,
  Card,
  Stack,
  Grow,
  Typography,
  CardContent,
  Paper,
  Alert,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Container,
  Tooltip,
  IconButton
} from '@mui/material'
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import React from 'react'
import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CurrencySimbol } from "../../utilities";
import { v4 as uuidv4 } from "uuid";
import { AddBoxTwoTone } from "@mui/icons-material";

// TODO: devo selezionare quale preventivo usare per la creazione della spedizione e
//      inserire nel contesto la sigla del servizio. 
//      una volta fatto ciò posso passarlo a servizi.js da SpedizioniMainForm e utilizzarlo per 
//      inizializzare il servizio


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




const PreventivoSingolo = ({DatiPreventivo,creaSpedizioneAuto}) => {

  const [LogoSoc, setLogoSoc] = useState([]);             
  const [isDettaglioOpen, setIsDettaglioOpen] = useState(false) 
  const [LogoPreventivo, setLLogoPreventivo] = useState("");             
 

    useEffect(() => {
      let path = window.location.href
      let splitpath = path.split("/");
      let public_path = splitpath[0] + '//'+splitpath[2] + '/';

      if (JSON.parse(localStorage.getItem("configurazioni")).logoFile512) {
        setLogoSoc(
          public_path + JSON.parse(localStorage.getItem("configurazioni")).logoFile512
        );
      }



    if ( DatiPreventivo?.ImmagineLogo){
      //img.src = 'data:image/png;base64,' + DatiPreventivo.ImmagineLogo;
      setLLogoPreventivo('data:image/png;base64,' + DatiPreventivo.ImmagineLogo)
    }  
  
  }, [])



  const handleButtonClick = () => {
    setIsDettaglioOpen(!isDettaglioOpen);
  };

  function fillImporto(){
    return (
      <>
      <Stack direction="row" spacing={8}>
        <Alert severity="warning" icon={<></>} >

          <TableContainer sx={{ width: 1 }}>
            {/* <strong>Riepilogo merce</strong> */}

            <Table >
              <TableBody>
                    
                {/* genTableRowsPreventivo()*/ } 
                {DatiPreventivo.imprighe.map((row) => (
                  <TableRow
                    key={uuidv4()}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                        <TableCell align="left" style={{borderBottom:"none"}} key={uuidv4()}>
                          {row.VoceDescrizione}
                        </TableCell>

                        <TableCell align="right" style={{borderBottom:"none"}} key={uuidv4()}>
                          {row.Importo} {/*&nbsp;({CurrencySimbol(DatiPreventivo.Valuta)}) */}
                        </TableCell>  
                      </TableRow>
                    ))}
                    
                    <TableCell align="center" style={{borderBottom:"none"}} key={uuidv4()}>
                      <Typography sx={{fontWeight:'bold'}}>
                        Totale: 
                      </Typography>
                    </TableCell>
                    <TableCell align="center" style={{borderBottom:"none"}} key={uuidv4()}>
                      <Typography sx={{fontWeight:'bold'}}>
                        {DatiPreventivo.Importo}&nbsp;&nbsp;{CurrencySimbol(DatiPreventivo.Valuta)}
                      </Typography>
                    </TableCell>     
                  </TableBody>
                </Table>
              </TableContainer>
            </Alert>
          </Stack>

          </>
        );
      }

    const getDescrizioneWidth = () =>{
      if (DatiPreventivo.Titolo !== '' ){
        return (150)
      }
      else{
        return(450);
      }
    }

    const getDescrizione = () =>{
      let ret = DatiPreventivo.Tariffa_TestoPerWeb;
      if ( ret === '' || ret === undefined || ret === null ){ 
        ret = DatiPreventivo.Descrizione;
      }
      return(`${ret}`)
    }



    const creaSpedizioneAutoVisible = () => {
      let config = JSON.parse(localStorage.getItem("configurazione"));
      let spedElement ={};
      config.map((element) => {
        if(element.name === 'spedizioni'){
          spedElement = element;
        }
      });
      return spedElement.visible;   
    }

    const getImportoWidth = () =>{
      if ( creaSpedizioneAutoVisible()  ){
        return (145)
      }
      else{
        return(240);
      }
    }


  return (
    <Alert severity="info" icon={<></>} sx={{ background: (theme) => theme.palette.error.light + "25" , width:"100%"}}   alignItems="center"
    justifyContent="center">
      <Container key={uuidv4()}>
        <div style={{width:1160}} key={uuidv4()}>
          <Box  sx={{ml:-1,mr:2, mt:3, mb:3,overflow:"auto"}} key={uuidv4()}>
            <Stack direction={"column"}>
                <Card  orientation="horizontal" variant="outlined"  >
                  <Stack direction={"row"}>
                    <Item key={uuidv4()}>
                      
                      {LogoPreventivo.length > 0?(  
                        <CardContent sx={{}} style={{width:350, height:100}}>
                        <Grow
                              in={
                              LogoPreventivo.length > 0
                            }
                            style={{ transformOrigin: "0 0 0" }}
                            {...(LogoPreventivo.length > 0 ? { timeout: 1000 } : {})}
                          >
                          <img
                            src={
                              LogoPreventivo.length > 0? 
                              LogoPreventivo
                              :
                              require("../../static/logo_true.png")
                            }
                            alt={"..."}
                            //style={{ width: 100, height: 100 }}
                          />
                        </Grow>
                        </CardContent>
                      ):(
                        <CardContent  style={{width:350, height:50 }}>
                        <Grow
                          in={
                            JSON.parse(localStorage.getItem("configurazioni"))
                              .logoFile512 && LogoSoc.length > 0
                          }
                          style={{ transformOrigin: "0 0 0" }}
                          {...(LogoSoc.length > 0 ? { timeout: 1000 } : {})}
                        >
                          <img
                            src={
                            LogoSoc.length > 0? 
                              JSON.parse(localStorage.getItem("configurazioni")).logoFile512
                            :
                              require("../../static/logo_true.png")
                            }
                            alt={"..."}
                            style={{ width: 100, height: 100 }}
                          />
                        </Grow>
                        </CardContent>
                      )}
                    </Item>

                    {DatiPreventivo.Titolo?(      
                      <Item elevation={1} key={uuidv4()}>
                        <CardContent sx={{ml:2 , mt:3}} style={{width:250}}>
                          <Typography variant="h5" color="text.primary">
                            {DatiPreventivo.Titolo}                             
                          </Typography>
                        </CardContent>
                      </Item>):(<></>)
                    }


                    <Item elevation={1} key={uuidv4()}>
                      <CardContent sx={{ml:2, mt:2}} style={{width:getDescrizioneWidth(), textAlign: 'left' }}> 
                        {/* <Typography variant="body1" color="text.primary">
                          {DatiPreventivo.Descrizione}
                        </Typography> */}
                        <ReactMarkdown
                          linkTarget="_blank"
                          children={getDescrizione()}
                          rehypePlugins={[rehypeRaw]}
                        >
                          {getDescrizione()}
                        </ReactMarkdown>
                      </CardContent>
                    </Item>

                    <Item key={uuidv4()}>
                      <CardContent sx={{ml:0}} style={{width:getImportoWidth()}}>
                        <Typography gutterBottom variant="h5" component="div">
                          Totale:
                        </Typography>
                        <Typography variant="h5" color="text.secondary">
                          {DatiPreventivo.Importo}
                        </Typography>
                      </CardContent>
                    </Item>

                    {/* //12/11/2024 Greg: Aggiunto  controllo se autorizzato a creare spedizioni */}
                    {
                      creaSpedizioneAutoVisible() ? 
                      (                    
                        <Item key={uuidv4()}>
                          <CardContent sx={{ml:0}} style={{width:60}}>
                            <Tooltip title="Crea spedizione" sx={{mt:2,ml:-0.5}}>
                              <IconButton 
                                color="success"
                                onClick={() => {creaSpedizioneAuto(DatiPreventivo.Servizio)}}
                              >
                                <AddBoxTwoTone />
                              </IconButton>
                            </Tooltip>
                          </CardContent>
                        </Item>
                      ):<></>
                    }

                  </Stack> 
                </Card>
                <Card sx={{ height:35}}>
        
                  <Box textAlign='center' key={uuidv4()}>
                      <Button 
                          size="small"
                          startIcon={isDettaglioOpen? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                          onClick={handleButtonClick}
                      >
                          Dettaglio
                      </Button>
                  </Box>
              </Card>
              {isDettaglioOpen?  (                  
                <Grow in={isDettaglioOpen}> 
                  <Alert severity="success" icon={<></>}>
                      {
                        fillImporto()
                      } 
                  </Alert> 
                </Grow>
              ):(
                <></>
                )
              }
            </Stack>
          </Box>
        </div>
      </Container>
    </Alert>
  )
}

export default PreventivoSingolo