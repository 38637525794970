import createDataContext from "./createContext";
import { WebTrakingService } from "../api";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";




const NUOVO_PREVENTIVO = "nuovo_preventivo";
const CONFIG_PREVENTIVO = "config_preventivo";
const INDICATORI_MITT = "indicatori_mit";
const INDICATORI_DEST = "indicatori_dest";
const SERVIZI = "servizi";
const VALUTE = "valute";
const TIPI_INCASSO = "tipi_incasso"
const ADR_CLASSI = "adr_classi";
const ADR_IMBALLAGGI = "adr_imballaggi";
const ERROR_MESSAGGE = "error_messagge";
const CONTA_VALIDI = "conta_validi";
const RESULT = "result";
const COMPLETE_RESULT = "complete_result";
const SVUOTA_FORM = "svuota_form";



const INITIAL_STATE = {
    form:[],
    configPreventivo:[],
    indicatoriMitt:[],
    indicatoriDest:[],
    servizi:[],
    valuteList:[],
    tipiIncassi:[],
    adrClassi:[],
    adrImballaggi:[],
    result:[],
    contaValidi:0,
    completeResult:{}
  };

const preventivinewReducer = (state, action) => {
  switch (action.type) {
    case NUOVO_PREVENTIVO: {
        return { ...state, form:action.payload };
      }
    case CONFIG_PREVENTIVO:{
        return { ...state, configPreventivo: action.payload };
    }
    case INDICATORI_MITT:{
        return { ...state, indicatoriMitt: action.payload };
    }
    case INDICATORI_DEST:{
        return { ...state, indicatoriDest: action.payload };
    }
    case SERVIZI: {
      return { ...state, servizi: action.payload };
    }
    case VALUTE:{
      return{...state, valuteList:action.payload};
    }
    case TIPI_INCASSO:{
      return {...state, tipiIncassi:action.payload};
    }
    case ADR_CLASSI:{
      return { ...state, adrClassi: action.payload };
    }
    case ADR_IMBALLAGGI:{
      return { ...state, adrImballaggi: action.payload };
    }
    case ERROR_MESSAGGE: {
      return { ...state, errorMessage2: action.payload };
    }
    case RESULT:{

      return{...state, result:action.payload}
    }
    case CONTA_VALIDI:{
      return{...state, contaValidi:action.payload}
    }
    case COMPLETE_RESULT:{    //TODO: fondere result e complete_result
      return{...state, completeResult:action.payload}
    }
    default: {
      return state;
    }
  }
};

const popolaNuovoPreventivo = (dispatch) => async(preventivo) => {
  dispatch({type:NUOVO_PREVENTIVO, payload:preventivo});
  //localStorage.setItem("preventivo_form", JSON.stringify(preventivo));

}

const postNuovoPreventivo = (dispatch) => async (preventivo/*, handleCloseDialog*/, navigate, setLoading) => {
  //attivo cerchio caricamento e blocco bottoni
  setLoading(true);
  //rettifico mittente e destinatario:
  if (preventivo.MittLocalita.includes(" - ")) {
    let mitloc = preventivo.MittLocalita;
    preventivo.MittLocalita = mitloc.split(" - ")[1];
  }

  if (preventivo.DestLocalita.includes(" - ")) {
    let destloc = preventivo.DestLocalita;
    preventivo.DestLocalita = destloc.split(" - ")[1];
  }

  const response = await WebTrakingService.post(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/preventivi/generatore`,
    preventivo
  );
  if (response) {

    if (response.data.Success) {
      dispatch({type:NUOVO_PREVENTIVO, payload:preventivo});
      dispatch({type:RESULT, payload:response.data.data});
      dispatch({type:CONTA_VALIDI, payload:response.data.ContaValidi});
      dispatch({type:COMPLETE_RESULT, payload:response.data});
      
     // localStorage.setItem("preventivo_form", JSON.stringify(preventivo));

      sendNotification(
        <NotificationContent service={"OK!"} />,
        "Preventivo calcolato con successo!",
        "success",
        5
      );

      navigate("/preventivi/preventivocalcolato")
      //disattivo cerchio caricamento e attivo bottoni
      setLoading(false);
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Message,
        "error",
        5,
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
      //disattivo cerchio caricamento e attivo bottoni
      setLoading(false);
    }
  }
};

const getServizi = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/servizi`
  );
  if (response) {
    dispatch({ type: SERVIZI, payload: response.data.data });
  }
};

const getIndicatoriMitt = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/indicatorioperativi?mitdes=mit`
    );
    if (response) {
      dispatch({ type: INDICATORI_MITT, payload: response.data.data });
    }
  };
  
  const getIndicatoriDest = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/indicatorioperativi?mitdes=des`
    );
    if (response) {
      dispatch({ type: INDICATORI_DEST, payload: response.data.data });
    }
  };

const getConfigModePreventivo = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/preventivi/config`
    );
    if (response) {
      if (response.data?.data?.preventivo?.configurazione?.active) {
        dispatch({ type: CONFIG_PREVENTIVO, payload: response.data.data.preventivo });
      }
      else{
        dispatch({ type: CONFIG_PREVENTIVO, payload: response.data.data.ritiri_assegnato });
      }
    }
  };


  const getTipiIncassi = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/tipiincasso`
    );
    if (response) {
      dispatch({ type: TIPI_INCASSO, payload: response.data.data });
    }
  };
  
  const getValute = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/valute`
    );
    if (response) {
      dispatch({ type: VALUTE, payload: response.data.data });
    }
  };


  const getImballaggi = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/adr/imballaggi`
    );
    if (response) {
      dispatch({ type: ADR_IMBALLAGGI, payload: response.data.data });
    }
  };

  const getClassi = (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/adr/classi`
    );
    if (response) {
      dispatch({ type: ADR_CLASSI, payload: response.data.data });
    }
  }; 

  const resetPreventivo = (dispatch) => async () => {


      dispatch({ type: ADR_CLASSI, payload:[] });

  };

  const postEmailPreventivo = (dispatch) => async (PrgPreventivo) => {

    //rettifico mittente e destinatario:

    const response = await WebTrakingService.post(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/preventivi/emailpreventivo/${PrgPreventivo}`      
    );
    if (response) {
  
      if (response.data.Success) {
       
  
        sendNotification(
          <NotificationContent service={"OK!"} />,
          "Email inviata con successo!",
          "success",
          5
        );
  
  
      } else {
        sendNotification(
          <NotificationContent service={"Errore!"} />,
          response.data.Message,
          "error",
          5,
        );
        dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
      }
    }
  };


export const { Provider, Context } = createDataContext(
    preventivinewReducer,
  {
    postNuovoPreventivo,
    getConfigModePreventivo,
    getIndicatoriDest,
    getIndicatoriMitt,
    getServizi,
    getTipiIncassi,
    getValute,
    getClassi,
    getImballaggi,
    popolaNuovoPreventivo,
    resetPreventivo,
    postEmailPreventivo
  }, // actions
  INITIAL_STATE // initial state
);
